import Image from 'next/image';

import { EPICExplanation } from '@/components/atomic/atoms';
import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_ACTION
} from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { convertToShortMonthWithTimeFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';

const NewAssignedUserEventCard = ({
  containerClassName = 'flex gap-2 md:gap-4 bg-event-gradient rounded-t-[7px] w-[300px] md:w-[420px] p-3 relative',
  isPreviewCardOpen,
  onClickCard = () => {},
  upcomingUserEventDetail: {
    checkoutEventIconUrl,
    eventCityOrAddress,
    eventDate,
    eventStageUpdatedAt,
    eventType,
    eventVerticalNames,
    expectedGuestCount,
    hostCreditEntityName,
    hostName,
    hostSegmentName,
    userEventId,
    userEventNumber,
    zendeskTicketId
  }
}) => {
  const tooltipConfigList = [
    { target: `zendesk-ticket-id-${userEventId}`, text: 'Zendesk Ticket Id' },
    { target: `user-event-number-${userEventId}`, text: 'User Event Number' }
  ];

  const [isMobile] = useIsMobile();
  return (
    <div
      className={`flex flex-col border border-pink rounded-lg shadow-navigate-cart hover:border-brand relative ${
        isPreviewCardOpen ? '!border-brand' : ''
      }`}
      onClick={onClickCard}
    >
      <Image
        alt='triangle icon'
        className={`absolute -right-4 top-10 ${
          isPreviewCardOpen ? '' : 'hidden'
        }`}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/triangle-icon.svg`}
        style={{ height: 24, width: 27 }}
        unoptimized={true}
        width={0}
      />
      <TextWithIcon
        {...{
          alt: 'time red red',
          gap: 'gap-[2px] md:gap-1 px-1',
          className:
            'shadow-none py-[1px] absolute top-[1px] right-[1px] bg-white rounded-bl-lg rounded-tr-lg px-1 z-10',
          icon: 'time-icon-red.svg',
          iconHeight: isMobile ? 8 : 12,
          iconWidth: isMobile ? 8 : 12,
          label: convertToShortMonthWithTimeFormat(eventStageUpdatedAt),
          labelStyle:
            'text-[8px] md:text-[10px] font-light md:font-medium text-dim-gray'
        }}
      />
      <div className={`${containerClassName} pt-4 md:pt-6`}>
        <Image
          alt='event image'
          className='rounded-lg'
          height={0}
          src={`${mediaStoreBaseURL}/${checkoutEventIconUrl}`}
          style={{ height: 72, width: 72 }}
          unoptimized={true}
          width={0}
        />
        <Text
          {...{
            className: 'text-sm text-white truncate-text-three-line',
            content: (
              <EPICExplanation
                {...{
                  epicParameters: {
                    city: eventCityOrAddress,
                    eventDate,
                    eventType,
                    guests: expectedGuestCount,
                    hostCreditEntityName,
                    hostName,
                    hostType: hostSegmentName,
                    userEventId,
                    verticals: eventVerticalNames
                  }
                }}
              />
            )
          }}
        />
      </div>

      <div className='flex flex-col md:flex-row gap-3 md:gap-0 md:items-center p-3 justify-between'>
        <div className='flex gap-4'>
          <TextWithIcon
            {...{
              alt: 'zendesk icon',
              className: 'shadow-none',
              icon: 'zendesk-icon.svg',
              iconHeight: isMobile ? 12 : 20,
              iconWidth: isMobile ? 12 : 20,
              id: `zendesk-ticket-id-${userEventId}`,
              label: parseNumberedLabel(zendeskTicketId),
              labelStyle: 'text-[10px] md:text-sm font-medium text-dim-gray'
            }}
          />
          <TextWithIcon
            {...{
              alt: 'user event brand icon',
              className: 'shadow-none',
              icon: 'user-event-brand-icon.svg',
              iconHeight: isMobile ? 12 : 20,
              iconWidth: isMobile ? 12 : 20,
              id: `user-event-number-${userEventId}`,
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-[10px] md:text-sm font-medium text-dim-gray'
            }}
          />
        </div>
        <div className='flex gap-4'>
          <Button
            {...{
              className:
                'border border-brand px-2 py-2 md:py-1 rounded text-brand font-medium text-xs',
              disabled: isPreviewCardOpen,
              label: USER_EVENT_ACTION.REASSIGN.label,
              onClick: (e) => {
                e.stopPropagation();
                onClickCard({ action: USER_EVENT_ACTION.REASSIGN.value });
              },
              width: 'w-1/2 md:w-auto'
            }}
          />
          <Button
            {...{
              className:
                'px-2 py-2 md:py-1 rounded text-white bg-brand-gradient font-medium text-xs',
              disabled: isPreviewCardOpen,
              label: USER_EVENT_ACTION.ACCEPT.label,
              onClick: (e) => {
                e.stopPropagation();
                onClickCard({ action: USER_EVENT_ACTION.ACCEPT.value });
              },
              width: 'w-1/2 md:w-auto'
            }}
          />
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default NewAssignedUserEventCard;
